import styled from "styled-components";

export const FileUploadContainer = styled.div`
    display: flex;
    width: 100%;
    min-width: 400px;
    max-width: 1440px;
    flex-direction: column;
    margin: 0 24px;
    
    @media (min-width: 1440px) {
        margin: 0 auto; /* 중앙 정렬 */
        padding: 0 24px;
    }
`;

export const FileUploadTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    //background: bisque;
`;

export const FileUploadTitle = styled.div`
    padding: 16px 0;
    color: #0D141C;
    font-size: 32px;
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    align-items: center;
    line-height: 40px;
`;

export const FileUploadSubTitle = styled.div`
    color: #020202;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    font-weight: 300;
    word-wrap: break-word;
    line-height: 21px;
`;

export const FileUploadContent = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .upload-title {
        color: #0D141C;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        width: 100%;
    }
    
    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .url {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0;
    }
    
    input {
        width: 100%;
        height: 50px;
        padding: 16px;
        border: 1px #DEE2E6 solid;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        background: rgba(255, 255, 255, 0.50);
        overflow: hidden;
        margin-right: 12px;
    }
    
    .finder {
        display: flex;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        gap: 5px;

        input {
            display: none;
        }

        label {
            cursor: pointer;
            color: #30B0C7;
            font-weight: 700;
        }
    }
`;

export const SpeakerOption = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    
    .label {
        margin-right: 32px;
    }
    
    .btn {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background: #F5F5F5;
        border: none;
        border-radius: 50%;
        color: #6D6D6D;
        justify-content: center;
        align-items: center;
    }
    
    span {
        color: #6D6D6D;
    }
`;

export const FileButtonStyle = styled.button`
    background-color: #30B0C7;
    color: #F7FAFC;
    height: 50px;
    width: 164px;
    padding: 14px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    font-family: Pretendard,sans-serif;

    &:disabled {
        background-color: #D9D9D9;
        color: white;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const InstrumentOption = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    .label {
        font-weight: 500;
        margin-right: 10px;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    input[type="checkbox"] {
        margin: 0 10px;
        width: 15px;
    }

    label {
    }
`;
