import axios from 'axios';

export const getVolume = async (id) => {

    const BACKEND_URL = 'https://audion.mago52.com'
    try{
        const response = await axios.get(`${BACKEND_URL}/api/user/${id}/volume`, {
            withCredentials: true,
        });

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
