import React, {useCallback, useState} from 'react';
import {
    CheckboxCell,
    FileIcon, SearchContainer, SearchIcon, SearchInput, Status,
    StyledTable,
    TableCell,
    TableContainer,
    TableHeader,
    ButtonGroup,
    Button,
    TableRow, TopBar
} from "../styles/FileTableStyles";
import {BiSearch} from "react-icons/bi";
import {useDispatch} from "react-redux";
import {fetchSearchData} from "../redux/listSlice";

const FileTable = ({list}) => {
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [allChecked, setAllChecked] = useState(false);
    // 전체 체크 상태 관리
    const [isAllChecked, setIsAllChecked] = useState(false);

    // 전체 선택/해제 함수
    const handleSelectAll = () => {
        const newCheckedState = !isAllChecked;
        setIsAllChecked(newCheckedState);
        list = (list.map((item) => ({...item, checked: newCheckedState})));
    };

    // 개별 체크 함수
    const handleCheckboxChange = (id) => {
        list =
            list.map((item) =>
                item.id === id ? {...item, checked: !item.checked} : item
            );
        // 모든 항목이 선택되었는지 확인하여 전체 체크박스 상태 업데이트
        const allChecked = list.every((item) =>
            item.id === id ? !item.checked : item.checked
        );
        setIsAllChecked(allChecked);
    };

    const handleSort = (column) => {
        let direction = 'asc';
        if (sortColumn === column && sortDirection === 'asc') {
            direction = 'desc';
        }
        setSortColumn(column);
        setSortDirection(direction);

        const sortedData = [...list].sort((a, b) => {
            const aValue = a[column] || '';
            const bValue = b[column] || '';

            // 파일 크기 정렬
            if (column === 'fileSize') {
                const getSizeInBytes = (size) => {
                    const unit = size.slice(-2); // 'MB', 'GB' 등 추출
                    const value = parseFloat(size.slice(0, -2)); // 숫자 추출
                    if (unit === 'GB') return value * 1024;
                    if (unit === 'MB') return value;
                    return 0; // 알 수 없는 단위는 0 처리
                };
                return direction === 'asc'
                    ? getSizeInBytes(aValue) - getSizeInBytes(bValue)
                    : getSizeInBytes(bValue) - getSizeInBytes(aValue);
            }

            // 날짜 정렬
            if (column === 'createdAt') {
                return direction === 'asc'
                    ? new Date(aValue) - new Date(bValue)
                    : new Date(bValue) - new Date(aValue);
            }

            // 기본 문자열 정렬
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        list = sortedData;
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            console.log(searchTerm);
            dispatch(fetchSearchData(searchTerm)); // 검색어로 fetchSearchData 호출
        }
    };

    // 검색 입력 핸들러
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div>
            <TopBar>
                <form onSubmit={handleSearchSubmit}>
                    <SearchContainer>
                        <SearchIcon>
                            <BiSearch size={16}/>
                        </SearchIcon>
                        <SearchInput
                            type="text"
                            placeholder="검색"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </SearchContainer>
                </form>
                <ButtonGroup>
                    <Button>
                        {/*<Filter size={16} />*/}
                        필터
                    </Button>
                    <Button>
                        열 맞춤설정
                    </Button>
                </ButtonGroup>
            </TopBar>
            <TableContainer>
                <StyledTable>
                    <thead>
                    <tr>
                        <TableHeader onClick={handleSelectAll}>
                            <input
                                type="checkbox"
                                checked={isAllChecked}
                                onChange={handleSelectAll}
                            />
                        </TableHeader>
                        <TableHeader onClick={() => handleSort('thumbnail')}>파일 형식</TableHeader>
                        <TableHeader onClick={() => handleSort('uploadedFileName')}>파일명</TableHeader>
                    <TableHeader onClick={() => handleSort('fileSize')}>파일 크기</TableHeader>
                    <TableHeader onClick={() => handleSort('duration')}>재생시간</TableHeader>
                    <TableHeader onClick={() => handleSort('createdAt')}>생성일시</TableHeader>
                    <TableHeader onClick={() => handleSort('decodingTime')}>분석 시간</TableHeader>
                    <TableHeader onClick={() => handleSort('status')}>주제 레이블링</TableHeader>
                </tr>
                </thead>
                <tbody>
                {list.map((item) => (
                    <TableRow key={item.id}>
                        <CheckboxCell>
                            <input
                                type="checkbox"
                                checked={item.checked}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        </CheckboxCell>
                        <TableCell>
                            <FileIcon src={item.thumbnail} alt="thumbnail"/>
                        </TableCell>
                        <TableCell>{item.uploadedFileName}</TableCell>
                        <TableCell>{item.fileSize}MB</TableCell>
                        <TableCell>{item.duration}</TableCell>
                        <TableCell>{item.createdAt}</TableCell>
                        <TableCell>{item.decodingTime}</TableCell>
                        <TableCell>
                            <Status status={item.status}>{item.status}</Status>
                        </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </StyledTable>
        </TableContainer>
        </div>
    );
};

export default FileTable;
