import styled from "styled-components";

export const Bar = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 72px;
    color: white;
    border-bottom: 1px #E5E8EB solid;
`;

export const MenuLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Logo = styled.img`
    width: 139px;
    height: 25px;
    cursor: pointer;
`;

export const Menu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 32px;
    flex: 1 1 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 36px;
    align-items: center;
`;

export const MenuButton = styled.button`
    display: flex;
    gap: 5px;
    flex-direction: row;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #0D141C;
    font-family: 'Pretendard', sans-serif;
    line-height: 21px;
    word-wrap: break-word;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;
