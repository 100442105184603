import styled from 'styled-components';

export const TableContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: white;
`;

export const TableHeader = styled.th`
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    color: #666;
    font-weight: 500;
`;

export const TableCell = styled.td`
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    color: #333;
`;

export const TableRow = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

export const CheckboxCell = styled.td`
    width: 40px;
    text-align: center;
`;

export const FileIcon = styled.img`
    width: 76px;
    height: 40px;
    border-radius: 4px;
`;

export const Status = styled.span`
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;

    ${({status}) => {
    switch (status) {
        case '라이브스타일':
            return 'background-color: #e8f0fe; color: #1a73e8;';
        case '예술':
            return 'background-color: #e6f4ea; color: #1e8e3e;';
        case '자기계발':
            return 'background-color: #fce8e6; color: #d93025;';
        default:
            return '';
    }
}}
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;

export const SearchContainer = styled.form`
    position: relative; /* 추가 */
    width: 176px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 36px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 12px;
  outline: none;

  &:focus {
    border-color: #1a73e8;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #8E8E93;
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #f8f9fa;
  }
`;
