import axios from 'axios';
import {queryString} from '../../utils/queryString';

export const getList = async (id) => {

    const BACKEND_URL = 'https://audion.mago52.com'

    const list = {
        // size: '1'
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/api/user/${id}?${queryString(list)}`,{
            withCredentials: true,
        });

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
