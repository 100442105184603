import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveButton } from "../redux/navigationSlice"; // 슬라이스 경로에 따라 수정
import { Nav, NavButton, NavButtonActive, SubNav, SubNavButton, SubNavButtonActive } from "../styles/NavStyles";
import {FaLock} from "react-icons/fa";

const Navigation = () => {
    const activeButton = useSelector((state) => state.navigation.activeButton); // 상태 가져오기
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleButtonClick = (buttonId) => {
        dispatch(setActiveButton(buttonId)); // 상태 업데이트
        navigate(`/${buttonId}`);
    };

    return (
        <Nav>
            <NavButton
                onClick={() => handleButtonClick("mypage")}
                as={activeButton === "mypage" ? NavButtonActive : NavButton}
            >
                홈
            </NavButton>
            <NavButton
                // onClick={() => handleButtonClick("customize")}
                onClick={()=>{}}
                // as={activeButton === "customize" ? NavButtonActive : NavButton}
            >
                프로젝트 만들기
            </NavButton>
            <SubNav>
                <SubNavButton
                    // onClick={() => handleButtonClick("upload")}
                    // as={activeButton === "upload" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    파일 업로드
                    <FaLock />
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("dashboard")}
                    as={activeButton === "dashboard" ? SubNavButtonActive : SubNavButton}
                >
                    지난 작업
                </SubNavButton>
            </SubNav>
            <NavButton
                // onClick={() => handleButtonClick("manage")}
                // as={activeButton === "manage" ? NavButtonActive : NavButton}
                onClick={()=>{}}
            >
                프로젝트 관리
            </NavButton>
            <SubNav>
                <SubNavButton
                    // onClick={() => handleButtonClick("apiKeys")}
                    // as={activeButton === "apiKeys" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    API 키
                    <FaLock />

                </SubNavButton>
                <SubNavButton
                    // onClick={() => handleButtonClick("billing")}
                    // as={activeButton === "billing" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    결제정보
                    <FaLock />

                </SubNavButton>
                <SubNavButton
                    // onClick={() => handleButtonClick("usage")}
                    // as={activeButton === "usage" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    Usage
                    <FaLock />

                </SubNavButton>
            </SubNav>
            <NavButton
                // onClick={() => handleButtonClick("learn")}
                // as={activeButton === "learn" ? NavButtonActive : NavButton}
                onClick={()=>{}}
            >
                가이드
            </NavButton>
            <SubNav>
                <SubNavButton
                    // onClick={() => handleButtonClick("exploreGuides")}
                    // as={activeButton === "exploreGuides" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    가이드 탐색
                    <FaLock />

                </SubNavButton>
                <SubNavButton
                    // onClick={() => handleButtonClick("docs")}
                    // as={activeButton === "docs" ? SubNavButtonActive : SubNavButton}
                    onClick={()=>{}}
                >
                    가이드 문서
                    <FaLock />

                </SubNavButton>
            </SubNav>
        </Nav>
    );
};

export default Navigation;
