import axios from "axios";

// const BACKEND_URL = "http://147.47.41.49:8001";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const handleSummary = async (srtFile, BACKEND_URL, setSummary, setProgress) => {
    const formData = new FormData();
    formData.append('service_name', 'MllmSummary');
    formData.append('text', '요약해 주세요. ### ' + srtFile);
    formData.append('mode', 'summary');

    try {
        let summaryUploadProgress = 0;
        let summaryDownloadProgress = 0;

        const response = await axios.post(`${BACKEND_URL}/service`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                summaryUploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const totalProgress = Math.round(50 + (summaryUploadProgress + summaryDownloadProgress) / 4); // 나머지 50% 진행 상태
                setProgress(totalProgress);  // 전체 진행 상태 업데이트
            },
            onDownloadProgress: progressEvent => {
                summaryDownloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const totalProgress = Math.round(50 + (summaryUploadProgress + summaryDownloadProgress) / 4); // 나머지 50% 진행 상태
                setProgress(totalProgress);  // 전체 진행 상태 업데이트
            },
            withCredentials: true
        });

        // 최종적으로 진행 상태를 100%로 설정
        setProgress(100);

        const data = response.data; // axios는 json 변환이 자동으로 이루어짐
        console.log('summary', data);

        // 요약 결과를 저장
        setSummary(data.content.text);

    } catch (error) {
        console.error('Error generating summary:', error);
        alert('Error generating summary. Please try again.');

        // 에러가 발생했을 때도 진행 상태를 100으로 설정
        setProgress(100);
    }
};


export const handleInsight = async (text) => {
    const formData = new FormData();
    formData.append('service_name', 'MllmChat');
    formData.append('text', text);
    formData.append('mode', 'chat');

    try {
        const response = await fetch(`${BACKEND_URL}/service`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        if (!response.ok) {
            console.error(`Invalid POST request: ${response.status}`);
            alert(`Invalid POST request: ${response.status}`);
            return;
        }

        const data = await response.json();

        console.log('insight', data);

        return data.content.text;

    } catch (error) {
        console.error('Error generating questions and answers:', error);
        alert('Error generating questions and answers. Please try again.');
    }
};
