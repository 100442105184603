// redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import listReducer from "./listSlice";
import navigationReducer from "./navigationSlice"; // 새로 만든 슬라이스 가져오기

const store = configureStore({
    reducer: {
        list: listReducer,         // 기존 리스트 슬라이스
        navigation: navigationReducer, // 새로운 네비게이션 슬라이스 추가
    },
});

export default store;
