import React from 'react';
import {Progress} from 'antd';
import 'antd/dist/reset.css'; // Ant Design의 기본 스타일을 import 해야 합니다.
import styled from 'styled-components';

// Styled component for the container
const ProgressContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    border-radius: 12px;
    border: 1px #E7E7E7 solid;
    padding:  5px 16px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

// Styled component for the label
const ProgressLabel = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-left: 12px;
    
    .title{
        font-weight: 400;
        line-height: 16px;
    }
`;

const PercentLabel = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 12px;
    color: #AEAEB2;
`;

const ProgressBar = ({progress, videoTitle, thumbnailUrl}) => {
    const {step, percent} = progress;

    return (
        <ProgressContainer>
            <div style={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontSize: '12px',
                marginBottom: '4px',
            }}>
                {videoTitle && (
                    <div>
                        {thumbnailUrl && <img src={thumbnailUrl} alt="썸네일" style={{width: '34px'}}/>}
                    </div>
                )}
                <ProgressLabel>
                    {step === 'upload' && `파일 업로드 중.. `}
                    {step === 'download' && `자막 생성 중..`}
                    {step === 'summaryUpload' && `요약 생성 중..`}
                    {step === 'summaryDownload' && `다운로드 중..`}
                    {step === 'complete' && `완료`}
                    <div className='title'>{videoTitle}</div>
                </ProgressLabel>
            </div>
            <div style={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                gap: '16px',
            }}>
                <Progress
                    style={{
                    }}
                    percent={percent}
                    strokeColor={{
                        from: '#108ee9',
                        to: '#30B0C7',
                    }}
                    showInfo={false}
                    strokeWidth={8}
                />
                <PercentLabel>
                    {step === 'upload' && `${percent}%`}
                    {step === 'download' && `${percent}%`}
                    {step === 'summaryUpload' && `${percent}%`}
                    {step === 'summaryDownload' && `${percent}%`}
                    {step === 'complete' && `${percent}%`}
                </PercentLabel>
            </div>
        </ProgressContainer>
    );
};


export default ProgressBar;
