import axios from 'axios';
import {queryString} from '../../utils/queryString';

export const getSearch = async (id, name) => {

    const BACKEND_URL = 'https://audion.mago52.com'

    const list = {
        // size: '1'
        fileName: name
    }

    const response = await axios.get(`${BACKEND_URL}/api/user/${id}/search?${queryString(list)}`,{
        withCredentials: true,
    });
    return response.data;
}

