import styled from 'styled-components';

export const CurationStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    min-height: 100vh;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
`;

export const QurationHeader = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    
    span{
        font-weight: 700;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
`;

export const NextButton = styled.button`
    display: flex;
    align-items: center;
    margin: 2rem 2rem;
    padding: 1rem 2rem;
    border: 2px solid black;
    border-radius: 14px;
    background-color: black;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #fff;
        color: black;
    }
`;

export const BackButton = styled.button`
    display: flex;
    align-items: center;
    margin: 2rem 2rem;
    padding: 1rem 2rem;
    border: 2px solid black;
    border-radius: 14px;
    background-color: black;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #fff;
        color: black;
    }
`;

export const QurationBody = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* 요소들이 줄을 넘어갈 때 자동으로 다음 줄로 이동하도록 설정 */
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 2rem;
`;


export const OptionButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 140px;
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 14px;
    color: black;
    background-color: #fff;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    
    img{
        margin-right: 20px;
    }
    
    span{
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
    }
`;
