import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';

import MenuBar from "../components/MenuBar";
import {LoginButtonStyle, LoginContainer, LoginInputLabelStyle, LoginInputTextStyle} from "../styles/LoginStyles";
import nav from "../components/Nav";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const HEADERS = {
        'Content-Type': 'application/json',
    };


    const toggleVisibility = () => {
        setVisible(!visible);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '' || password === '') {
            alert('Please enter your email and password.');
            return;
        }

        const user = {email, password};

        try {
            const response = await fetch(`${BACKEND_URL}/login`, {
                method: 'POST',
                headers: HEADERS,
                credentials: 'include', // 쿠키 및 인증 정보 포함
                body: JSON.stringify(user),
            });

            const data = await response.json();

            console.log('data: ', data);

            if (data.userId === '') {
                alert('Login failed');
            } else if (data.status === 'success') {
                alert('Login successful');

                // userId localStorage에 저장
                localStorage.setItem('userId', data.userId);

                navigate('/');
                setEmail('');
                setPassword('');
            } else {
                console.error('Unexpected response status:', response.data.status);
            }
        } catch (error) {
            console.error('Error during login request:', error);
            alert('An error occurred during login. Please try again.');
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            width: "100%",
            height: "100%"
        }}>
            <MenuBar/>
            <LoginContainer>
                <div style={{fontSize: 30}}>
                    로그인
                </div>
                <form onSubmit={handleSubmit}>
                    <div style={{margin: '50px 0 50px 0', fontSize: 16, display: 'flex', flexDirection: 'column'}}>
                        <LoginInputLabelStyle>Email</LoginInputLabelStyle>
                        <LoginInputTextStyle type="text" placeholder="Your email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}/>
                        <LoginInputLabelStyle>Password</LoginInputLabelStyle>
                        <div style={{position: 'relative'}}>
                            <LoginInputTextStyle type={visible ? "text" : "password"} placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}/>
                            <div style={{
                                position: 'absolute',
                                right: '15px',
                                top: '50%',
                                transform: 'translateY(-60%)',
                                cursor: 'pointer'
                            }}
                                 onClick={toggleVisibility}>
                                {visible ? <FaRegEye/> : <FaRegEyeSlash/>}
                            </div>
                        </div>
                        <LoginButtonStyle>
                            로그인
                        </LoginButtonStyle>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{color: '#605D64', fontSize: 14, fontWeight: 600, margin: '20px 0 20px 0'}}>
                                계정이 없으신가요?
                            </div>
                            <button onClick={() => navigate('/signup')} style={{
                                color: '#6D878C',
                                fontSize: 16,
                                fontFamily: 'Pretendard, sans-serif',
                                fontWeight: 600,
                                margin: '20px 0 20px 0',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer'
                            }}>회원가입
                            </button>
                        </div>
                    </div>
                </form>
            </LoginContainer>
        </div>
    );
}

export default LoginPage;
