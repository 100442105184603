import axios from 'axios';

// 단계별 진행 상태 업데이트
export const handleSeparation = async (e, {
    file, youtubeUrl, setIsLoading, setProgress, BACKEND_URL, instrumentsString, setResult
}) => {
    e.preventDefault();

    if (!file && !youtubeUrl) {
        alert('Please select a file or enter a YouTube URL.');
        return;
    }

    const formData = new FormData();
    let url = '';
    if (file) {
        formData.append('file', file);
        formData.append('call_type', 'uri');
        formData.append('uri', file.name);
        url = `${BACKEND_URL}/service`;
    } else if (youtubeUrl) {
        formData.append('uri', youtubeUrl);
        formData.append('call_type', 'url');
        url = `${BACKEND_URL}/serviceUrl`;
    }
    formData.append('service_name', 'VoiceSeparation');
    formData.append('targets', instrumentsString);
    formData.append('to_speech', 'False');

    setIsLoading(true);
    setProgress({step: 'upload', percent: 0});

    console.log('url:', url);

    try {
        // 첫 번째 요청 (파일 업로드 및 자막 데이터 받기)
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                const uploadProgress = Math.round((progressEvent.loaded * 25) / progressEvent.total);
                setProgress({step: 'upload', percent: uploadProgress});
            },
            onDownloadProgress: progressEvent => {
                const downloadProgress = Math.round(25 + (progressEvent.loaded * 25) / progressEvent.total);
                setProgress(prev => ({
                    step: 'download',
                    percent: Math.max(prev.percent, downloadProgress)
                }));
            },
            withCredentials: true
        });

        console.log('Response:', response);

        const data = response.data;
        setResult(data);
        // const text = parseText(data.content);

        setProgress({step: 'complete', percent: 100});

    } catch (error) {
        console.error('Error processing request:', error);
        alert('Error processing request. Please try again.');
        setProgress({step: 'complete', percent: 100});
    } finally {
        // setIsLoading(false);
    }
};
