// redux/listSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVolume } from '../apis/dashboard/volume'; // fetch functions
import { getList } from '../apis/dashboard/list';
import {getSearch} from "../apis/dashboard/search"; // fetch functions

// Async thunk for fetching data
export const fetchListData = createAsyncThunk('list/fetchListData', async (userId) => {
    const response = await getList(userId);
    return response.data;
});

export const fetchVolumeData = createAsyncThunk('list/fetchVolumeData', async (userId) => {
    const response = await getVolume(userId);
    return response.data;
});

export const fetchSearchData = createAsyncThunk('list/fetchSearchData', async (userId) => {
    const response = await getSearch(userId);
    return response.data;
});

// Slice
const listSlice = createSlice({
    name: 'list',
    initialState: {
        list: [],
        volume: { totalCount: 0, totalSize: 0, video: 0, audio: 0 },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListData.fulfilled, (state, action) => {
                state.list = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchListData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchListData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchVolumeData.fulfilled, (state, action) => {
                state.volume = action.payload;
            });
    },
});

export default listSlice.reducer;
