import styled from "styled-components";

export const DashhboardContainer = styled.div`
    display: flex;
    width: 100%;
    min-width: 400px;
    max-width: 1440px;
    flex-direction: column;
    margin: 0 24px;

    @media (min-width: 1440px) {
        margin: 0 auto; /* 중앙 정렬 */
        padding: 0 24px;
    }
`;

export const VolumeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 145px;
    margin: 24px 0;
    gap: 19px;
`;

// 공통 스타일
const BaseBox = styled.div`
    display: inline-flex;
    flex: 1;
    min-width: 500px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 12px;
    padding: 11px 15px;
    
    .icon {
        width: 36px;
        height: 36px;
        background: rgba(255, 255, 255, 0.16);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    img {
        width: 20px;
        height: 20px;
    }
    
    
    .content {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    
    .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 49px;
        color: white;
        font-weight: 500;
        font-size: 20px;
    }
    
    .title {
        font-size: 14px;
    }
`;

// 확장 스타일
export const FileBox = styled(BaseBox)`
    background: #30B0C7;
`;

export const MediaBox = styled(BaseBox)`
    background: white;
    border: 1px solid rgba(222, 226, 230, 0.50);
    
    .icon {
        background: rgba(48, 176, 199, 0.37);
    }
    
    .title {
        color: #8B8D97;
    }
    
    .item {
        color: #45464E;
    }
`;

export const DashboardTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th, td {
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
        font-size: 15px;
    }
    
    td {
        font-size: 12px;
    }

    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

export const ToggleButton = styled.button`
    color: #21005D;
    background: none;
    border: none;
    cursor: pointer;
`;
