export const handleEmotions = async (setResultEmotions, path, id, BACKEND_URL) => {
    const formData = new FormData();
    formData.append('service_name', 'EmotionRecognition');
    formData.append('call_type', 'uri');
    formData.append('uri', `${path}`);

    // formdata log
    for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }

    try {
        const response = await fetch(`${BACKEND_URL}/serviceUri`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        const data = await response.json();
        console.log('Emotion data', data);

        let maxEmotion = '';
        let maxEmotionValue = 0;

        if (data.error) {
            maxEmotion = '없음';
            // setResultEmotions에 id와 maxEmotion을 저장
            setResultEmotions((prev) => {
                const newEmotions = {...prev};
                newEmotions[id] = maxEmotion;

                console.log('newEmotions:', newEmotions);
                return newEmotions;
            });
        }

        // ANGRY, HAPPINESS, NEUTRAL, SADNESS, SURPRISE 중에서 가장 큰 값을 찾아서 setResultEmotions에 저장

        for (const emotion in data.content.result.emotion) {
            console.log('Emotion:', emotion, 'Value:', data.content.result.emotion[emotion]);
            if (data.content.result.emotion[emotion] > maxEmotionValue) {
                maxEmotion = emotion;
                maxEmotionValue = data.content.result.emotion[emotion];
            }
        }

        console.log('Max emotion:', maxEmotion);
        // setResultEmotions에 id와 maxEmotion을 저장
        setResultEmotions((prev) => {
            const newEmotions = {...prev};
            newEmotions[id] = maxEmotion;

            console.log('newEmotions:', newEmotions);
            return newEmotions;
        });
    } catch (error) {
        console.error('Error analyzing emotions:', error);
    }
};
