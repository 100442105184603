import styled from "styled-components";

export const Button = styled.button`
    font-size: 32px;
    font-weight: 700;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 450px;
    height: 215px;
    color: black;
    padding: 25px;
    cursor: pointer;
    border-radius: 12px;
    background: #F7F8F9;
    border: 1px solid #DEDFE2;
`;

export const Product = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin-top: 50px;

    span {
        font-size: 32px;
        line-height: 40px;
        text-align: left;
    }
`;

export const ProductBtn = styled.button`
    font-size: 18px;
    font-weight: 700;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 250px;
    height: 150px;
    color: black;
    padding: 25px;
    cursor: pointer;
    background: white;
    border-radius: 12px;
    border: 1px solid #D1DBE8;
    margin-top: 20px;
    margin-right: 20px;
    
    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin-top: 15px;
    }
`;
