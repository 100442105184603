import React, {useState} from 'react';
import MenuBar from '../components/MenuBar';
import {
    BackButton,
    ButtonContainer,
    NextButton,
    OptionButton,
    QurationBody,
    QurationHeader,
    CurationStyles
} from "../styles/CurationStyles";
import styled from 'styled-components';

import cs from "../assets/curation/cs.svg";
import ai from "../assets/curation/ai.svg";
import discussion from "../assets/curation/discussion.svg";
import cubes from "../assets/project/cubes.svg";


import {HiOutlineClipboardDocumentList} from "react-icons/hi2";
import {LuListVideo} from "react-icons/lu";
import {PiWaveform} from "react-icons/pi";
import {MdOutlineLibraryMusic} from "react-icons/md";
import {TbUserQuestion} from "react-icons/tb";
import {MainContainer, MainContent} from "../styles/styles";
import {IoIosArrowRoundForward} from "react-icons/io";
import {BsQuestionLg} from "react-icons/bs";
import {HiSpeakerWave} from "react-icons/hi2";
import {FaArrowRight} from "react-icons/fa6";
import { VscSymbolKeyword } from "react-icons/vsc";
import { MdInsights } from "react-icons/md";
import { LiaExchangeAltSolid } from "react-icons/lia";
import {useNavigate} from "react-router-dom";



const FlowContainer = styled.div`
    display: flex;
    width: 700px;
    align-items: center;
    justify-content: space-around;
    color: white;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    margin: 30px 0;
`;

const Circle = styled.div`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #5b5b5b;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
`;

const Connector = styled.div`
    width: 30px; /* Adjust the length as needed */
    height: 3px;
    background-color: black;
`;

const FinalOptionButton = styled.button`
    width: 180px;
    height: 80px;
    margin: 10px;
    font-size: 16px;
    border: ${({selected}) => (selected ? '2px solid #1A80E5' : '1px solid #D1DBE8')};
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px;
`;

const Tooltip = styled.div`
    margin-left: 20px;
    padding: 20px;
    background-color: #D9DFE3;
    color: black;
    border-radius: 8px;
    font-size: 14px;
    display: ${({show}) => (show ? 'block' : 'none')};
`;


const CurationPage = () => {
    const [step, setStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSubOption, setSelectedSubOption] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const navigate = useNavigate();
    const [tooltips, setTooltips] = useState({
        '키워드 추출': false,
        '요약 및 인사이트 추출': false,
        '번역': false
    });

    const toggleOption = (option) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(option)
                ? prevSelected.filter((item) => item !== option)
                : [...prevSelected, option]
        );
        setTooltips((prevTooltips) => ({
            ...prevTooltips,
            [option]: !prevTooltips[option]
        }));
    };

    // subtitle generation으로 이동
    const onClickSubtitleGeneration = () => {
        navigate('/upload', {state: {isSubtitleGeneration: true}});
    }

    // subtitle generation으로 이동
    const onClickHome = () => {
        navigate('/');
    }

    const randomOptions = [
        '자동으로 회의록이 생성되면 좋겠어요.',
        '영상에 자막을 만들고 싶어요.',
        '노래에서 악기나 보컬을 분리하고 싶어요.',
        '영상의 소리를 편집하고 싶어요.',
        '잘 모르겠어요.'
    ];

    const steps = [
        '오디오 추출 및 포맷 변환',
        '음성/음원 분리, 추출',
        '화자 분할',
        '음성 인식',
        '자막/스크립트 생성',
    ];

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
    };

    const handleSelectSubOption = (option) => {
        setSelectedSubOption(option);
    };

    return (
        <MainContainer>
            <MenuBar/>
            <MainContent>
                <CurationStyles>
                    {step === 0 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                        }}>
                            <QurationHeader>Audion 큐레이션에 오신 것을 환영합니다.</QurationHeader>
                            <NextButton onClick={handleNext}>Next</NextButton>
                        </div>
                    )}

                    {step === 1 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <QurationHeader>고객의 니즈에 따른 음성 AI 서비스를 제공해드립니다.</QurationHeader>
                            <NextButton onClick={handleNext}>Next</NextButton>
                        </div>
                    )}

                    {step === 2 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <QurationHeader>필요하신 상황 별 혹은 기능 별로 추천해드릴 수 있어요.<br/>
                                어떤 방식으로 추천을 받으실지 골라주세요.</QurationHeader>
                            <QurationBody>
                                <OptionButton
                                    onClick={() => handleSelectOption("상황에 따른 추천")}
                                    style={{
                                        border: selectedOption === "상황에 따른 추천" ? '2px solid #1A80E5' : '1px solid #D1DBE8',
                                        cursor: 'pointer', // 활성화된 버튼은 포인터 커서를 표시
                                        opacity: 1, // 활성화된 버튼은 100% 불투명도
                                    }}
                                >
                                    <img src={discussion} alt="discussion" style={{ width: '80px', height: '80px' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        상황에 따른 추천<br />
                                        <span>구상 중인<br />서비스가 있어요.</span>
                                    </div>
                                </OptionButton>

                                <OptionButton
                                    onClick={() => {}}
                                    style={{
                                        border: '1px solid #D1DBE8',
                                        cursor: 'not-allowed', // 비활성화된 버튼은 클릭 불가 커서를 표시
                                        opacity: 0.5, // 비활성화된 버튼은 반투명으로 표시
                                    }}
                                >
                                    <img src={ai} alt="ai" style={{ width: '80px', height: '80px' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        기능에 따른 추천<br />
                                        <span>특정한 하나의<br />기능이 필요해요.</span>
                                    </div>
                                </OptionButton>

                                <OptionButton
                                    onClick={() => {}}
                                    style={{
                                        border: '1px solid #D1DBE8',
                                        cursor: 'not-allowed',
                                        opacity: 0.5,
                                    }}
                                >
                                    <img src={cs} alt="cs" style={{ width: '80px', height: '80px' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        잘 모르겠어요<br />
                                        <span>CS 연결을 통해<br />상담을 받을래요.</span>
                                    </div>
                                </OptionButton>
                            </QurationBody>
                            <ButtonContainer>
                                {selectedOption && <NextButton onClick={handleNext}>Next</NextButton>}
                            </ButtonContainer>
                        </div>
                    )}

                    {step === 3 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <QurationHeader>어떤 상황에서 서비스가 필요하신지 골라주세요<br/>(원하시는 서비스가 없다면 가장 가까운 것을
                                골라주세요)</QurationHeader>
                            <QurationBody>
                                {randomOptions.map((option, index) => (
                                    <OptionButton
                                        key={index}
                                        onClick={() => {
                                            if (index === 0) {
                                                handleSelectSubOption(option);
                                            }
                                        }}
                                        style={{
                                            border: selectedSubOption === option ? '2px solid #1A80E5' : '1px solid #D1DBE8',
                                            cursor: index === 0 ? 'pointer' : 'not-allowed', // 클릭 가능한 버튼은 포인터 커서 표시
                                            opacity: index === 0 ? 1 : 0.5, // 비활성화된 버튼은 반투명하게 표시
                                        }}
                                    >
                                        {index === 0 && (
                                            <HiOutlineClipboardDocumentList size="80px" style={{ marginRight: '10px' }} />
                                        )}
                                        {index === 1 && (
                                            <LuListVideo size="50px" style={{ marginRight: '10px' }} />
                                        )}
                                        {index === 2 && (
                                            <PiWaveform size="90px" style={{ marginRight: '10px' }} />
                                        )}
                                        {index === 3 && (
                                            <MdOutlineLibraryMusic size="50px" style={{ marginRight: '10px' }} />
                                        )}
                                        {index === 4 && (
                                            <TbUserQuestion size="50px" style={{ marginRight: '10px' }} />
                                        )}
                                        {option}
                                    </OptionButton>
                                ))}
                            </QurationBody>
                            <ButtonContainer>
                                <BackButton onClick={handleBack}>Back</BackButton>{selectedSubOption &&
                                <NextButton onClick={handleNext}>Next</NextButton>}
                            </ButtonContainer>
                        </div>
                    )}

                    {step === 4 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <QurationHeader>‘자동 회의록’을 생각하고 오셨군요.<br/>
                                우선 ‘자동 회의록’과 관련된 Audion의 기능이 어떻게 작동하는지 한 번 보실까요?</QurationHeader>
                            {/*<QurationBody>*/}
                            {/*    {finalOptions.map((option, index) => (*/}
                            {/*        <OptionButton*/}
                            {/*            key={index}*/}
                            {/*            onClick={() => handleFinalChoiceChange(option)}*/}
                            {/*            style={{*/}
                            {/*                borderColor: finalChoices.includes(option) ? 'lightblue' : 'initial'*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            {option}*/}
                            {/*        </OptionButton>*/}
                            {/*    ))}*/}
                            {/*</QurationBody>*/}
                            <ButtonContainer>
                                <BackButton onClick={handleBack}>Back</BackButton>
                                <NextButton onClick={handleNext}>Next</NextButton>
                            </ButtonContainer>
                        </div>
                    )}

                    {step === 5 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <QurationHeader>audion에서 제공하는 ‘자동 회의록’은 다릅니다.<br/>
                                우리는 아래 기능들을 사용하여, 높은 퀄리티의 결과물을 만들어냅니다.</QurationHeader>
                            <FlowContainer>
                                {steps.map((step, index) => (
                                    <React.Fragment key={index}>
                                        <Circle>{step}</Circle>
                                        {index < steps.length - 1 && <Connector/>}
                                    </React.Fragment>
                                ))}
                                <IoIosArrowRoundForward size='80px' color='black'/>
                                <Circle style={{width: '66px', height: '66px', backgroundColor: 'black'}}><BsQuestionLg
                                    size='35px'/>
                                </Circle>
                            </FlowContainer>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '30px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <HiSpeakerWave size='100px' style={{
                                        marginBottom: '15px'
                                    }}/>
                                    <div style={{
                                        backgroundColor: '#D9DFE3',
                                        width: '90px',
                                        height: '34px',
                                        borderRadius: '12px',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}>음원
                                    </div>
                                </div>

                                <FaArrowRight size='60px' color='black' style={{
                                    marginLeft: '80px',
                                    marginRight: '80px'
                                }}/>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '10px',
                                    border: '1px solid #B9BBBD',
                                    width: '495px',
                                    height: '280px',
                                    padding: '15px',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    fontSize: '12px',
                                }}>
                                    사람 1<br/>
                                    <span style={{marginBottom: '10px'}}>오늘 다룰 안건 중 가장 중요한 것이 무엇인가요?</span>
                                    사람 2<br/>
                                    <span style={{marginBottom: '10px'}}>사용자들의 체류시간을 높일 수 있는 방법을 같이 논의했으면 해요.</span>
                                    사람 1<br/>
                                    <span
                                        style={{marginBottom: '10px'}}>그렇군요. 그런데 체류시간보다 더 중요한 것은 가장 처음 페이지를 접하는 화면이 아닌가요?</span>
                                    사람 3<br/>
                                    <span>맞습니다. 전략 팀에서도 사용자들의 시선을 끌 수 있는 문구를 만드는 것이 중요할 것 같다고 해요.</span><span
                                    style={{marginBottom: '10px'}}>아무래도 짧고 강렬한 문구가 사람들의 기억에 남을 수 있으니까요.</span>
                                    사람 2<br/>
                                    <span style={{marginBottom: '10px'}}>혹시 참고할만 한 구체적인 사례가 있을까요?</span>
                                </div>

                            </div>
                            <ButtonContainer>
                                <BackButton onClick={handleBack}>Back</BackButton>
                                <NextButton onClick={handleNext}>Next</NextButton>
                            </ButtonContainer>
                        </div>
                        // <div>
                        //     <QurationHeader>선택하신 내용:</QurationHeader>
                        //     <ul>
                        //         <li>첫 번째 선택: {selectedOption}</li>
                        //         <li>두 번째 선택: {selectedSubOption}</li>
                        //         <li>마지막 선택: {finalChoices.join(', ')}</li>
                        //     </ul>
                        //     <ButtonContainer>
                        //         <NextButton onClick={handleBack}>Back</NextButton>
                        //     </ButtonContainer>
                        // </div>
                    )}
                    {step === 6 && (
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '50px',
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '75%',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'center',
                                }}>
                                    <div style={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        marginBottom: '20px',
                                    }}>결과물 예시
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '10px',
                                        border: '1px solid #B9BBBD',
                                        minHeight: '500px',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        fontSize: '12px',
                                        textAlign: 'start',
                                        padding: '25px',
                                        minWidth: '400px',
                                        marginRight: '100px',
                                        backgroundColor: 'white',
                                    }}>
                                        {tooltips['번역']
                                            ? (<div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                                justifyContent: 'start',
                                            }}>
                                                <b>사람 1</b>
                                                <span>오늘 다룰 안건 중 가장 중요한 것이 무엇인가요?</span>
                                                <span style={{marginBottom: '10px', color: '#1A83E5'}}>What is the most important agenda item to cover today?</span>
                                                <b>사람 2</b>
                                                <span>사용자들의 체류시간을 높일 수 있는 방법을 같이 논의했으면 해요.</span>
                                                <span style={{marginBottom: '10px', color: '#1A83E5'}}>I'd like to discuss ways to increase users' dwell time.</span>
                                                <b>사람 1</b>
                                                <span>그렇군요. 그런데 체류시간보다 더 중요한 것은 가장 처음 페이지를 접하는 화면이 아닌가요?</span>
                                                <span style={{marginBottom: '10px', color: '#1A83E5'}}>I see. But isn't the screen that users first come across more important than dwell time?</span>
                                                <b>사람 3</b>
                                                <span>맞습니다. 전략 팀에서도 사용자들의 시선을 끌 수 있는 문구를 만드는 것이 중요할 것 같다고 해요.</span><span>아무래도 짧고 강렬한 문구가 사람들의 기억에 남을 수 있으니까요.</span>
                                                <span style={{marginBottom: '10px', color: '#1A83E5'}}>That's right. The strategy team also said that it's important to create phrases that grab users' attention. I think short and strong phrases are more memorable.</span>
                                                <b>사람 2</b>
                                                <span>혹시 참고할만 한 구체적인 사례가 있을까요?</span>
                                                <span style={{marginBottom: '10px', color: '#1A83E5'}}>Are there any specific examples that I can refer to?</span>
                                            </div>)
                                            : (<div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                                justifyContent: 'start',
                                            }}>
                                                <b>사람 1</b>
                                                <span style={{marginBottom: '10px'}}>오늘 다룰 안건 중 가장 중요한 것이 무엇인가요?</span>
                                                <b>사람 2</b>
                                                <span
                                                    style={{marginBottom: '10px'}}>사용자들의 체류시간을 높일 수 있는 방법을 같이 논의했으면 해요.</span>
                                                <b>사람 1</b>
                                                <span
                                                    style={{marginBottom: '10px'}}>그렇군요. 그런데 체류시간보다 더 중요한 것은 가장 처음 페이지를 접하는 화면이 아닌가요?</span>
                                                <b>사람 3</b>
                                                <span>맞습니다. 전략 팀에서도 사용자들의 시선을 끌 수 있는 문구를 만드는 것이 중요할 것 같다고 해요.</span><span
                                                style={{marginBottom: '10px'}}>아무래도 짧고 강렬한 문구가 사람들의 기억에 남을 수 있으니까요.</span>
                                                <b>사람 2</b>
                                                <span style={{marginBottom: '10px'}}>혹시 참고할만 한 구체적인 사례가 있을까요?</span>
                                            </div>)}
                                        {tooltips['키워드 추출'] && (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '10px',
                                                alignItems: 'center',
                                            }}>
                                                <div style={{
                                                    fontWeight: '700',
                                                    marginRight: '10px',
                                                }}>
                                                    키워드
                                                </div>
                                                <div style={{
                                                    backgroundColor: '#D9DFE3',
                                                    borderRadius: '12px',
                                                    padding: '5px',
                                                    marginRight: '10px',
                                                }}>
                                                    사용자
                                                </div>
                                                <div style={{
                                                    backgroundColor: '#D9DFE3',
                                                    borderRadius: '12px',
                                                    padding: '5px',
                                                    marginRight: '10px',
                                                }}>
                                                    체류시간
                                                </div>
                                                <div style={{
                                                    backgroundColor: '#D9DFE3',
                                                    borderRadius: '12px',
                                                    padding: '5px',
                                                    marginRight: '10px',
                                                }}>
                                                    화면
                                                </div>
                                                <div style={{
                                                    backgroundColor: '#D9DFE3',
                                                    borderRadius: '12px',
                                                    padding: '5px',
                                                    marginRight: '10px',
                                                }}>
                                                    문구
                                                </div>
                                            </div>
                                        )}
                                        {tooltips['요약 및 인사이트 추출'] && (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: '10px',
                                                alignItems: 'start',
                                            }}>
                                                <div style={{
                                                    fontWeight: '700',
                                                    marginRight: '10px',
                                                }}>
                                                    요약
                                                </div>
                                                <div>
                                                    팀은 사용자의 체류 시간을 늘리는 방법과 첫 화면의 중요성을 논의 중입니다. 짧고 강렬한 문구로 사용자 관심을 끌어야
                                                    한다는 의견이 있으며, 구체적인 참고 사례가 필요하다는 요청도 나왔습니다.
                                                </div>
                                                <div style={{
                                                    fontWeight: '700',
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                }}>
                                                    인사이트
                                                </div>
                                                <div>
                                                    감정, 자막 정확도
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    minWidth: '770px',
                                }}>
                                    <QurationHeader style={{
                                        alignItems: 'start',
                                        textAlign: 'start',
                                        fontWeight: '400',
                                    }}>기본으로 제공되는 결과물과 함께<br/><span>아래 추가 옵션을 통해 더 나은 서비스를 경험하세요. <span style={{
                                        fontSize: '12px',
                                        textAlign: 'start',
                                    }}>(중복 선택
                                    가능)</span></span></QurationHeader>
                                    <FlowContainer style={{
                                        marginBottom: '60px',
                                    }}>
                                        {steps.map((step, index) => (
                                            <React.Fragment key={index}>
                                                <Circle>{step}</Circle>
                                                {index < steps.length - 1 && <Connector/>}
                                            </React.Fragment>
                                        ))}
                                        <IoIosArrowRoundForward size='80px' color='black'/>
                                        <Circle
                                            style={{
                                                width: '66px',
                                                height: '66px',
                                                backgroundColor: 'black'
                                            }}><BsQuestionLg
                                            size='35px'/>
                                        </Circle>
                                    </FlowContainer>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <FinalOptionButton selected={selectedOptions.includes('키워드 추출')}
                                                           onClick={() => toggleOption('키워드 추출')}
                                                           style={{
                                                               width: '180px',
                                                               height: '80px',
                                                           }}>
                                            <VscSymbolKeyword size='30px' style={{
                                                marginRight: '10px',
                                            }}/>
                                            키워드 추출
                                        </FinalOptionButton>
                                        <Tooltip show={tooltips['키워드 추출']}>
                                            AI가 음성에 대한 주요 키워드를 추출하고 정리해드려요.
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <FinalOptionButton selected={selectedOptions.includes('요약 및 인사이트 추출')}
                                                           onClick={() => toggleOption('요약 및 인사이트 추출')}
                                                           style={{
                                                               width: '180px',
                                                               height: '80px',
                                                           }}>
                                            <MdInsights size='50px' style={{
                                                marginRight: '10px',
                                            }}/>
                                            요약 및 인사이트 추출
                                        </FinalOptionButton>
                                        <Tooltip show={tooltips['요약 및 인사이트 추출']}>
                                            AI가 음성 내용을 요약하고 인사이트를 추출해드려요.
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <FinalOptionButton selected={selectedOptions.includes('번역')}
                                                           onClick={() => toggleOption('번역')}
                                                           style={{
                                                               width: '180px',
                                                               height: '80px',
                                                           }}>
                                            <LiaExchangeAltSolid
                                                size='25px' style={{
                                                marginRight: '15px',
                                            }}/>
                                            번역
                                        </FinalOptionButton>
                                        <Tooltip show={tooltips['번역']}> 원하는 언어로 번역해드려요.
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <ButtonContainer>
                                <BackButton onClick={handleBack}>Back</BackButton>
                                <NextButton onClick={handleNext}>Next</NextButton>
                            </ButtonContainer>
                        </div>
                    )}
                    {step === 7 && (
                        <div>
                            <img src={cubes} alt="cubes" style={{width: '80px', height: '80px'}}/>
                            <QurationHeader>‘Transcription’ 프로젝트를 생성했습니다!</QurationHeader>
                            <ButtonContainer>
                                <BackButton onClick={onClickHome}>홈으로</BackButton>
                                <NextButton onClick={onClickSubtitleGeneration}>바로 사용</NextButton>
                            </ButtonContainer>
                        </div>
                    )}
                </CurationStyles>
            </MainContent>
        </MainContainer>
    );
}

export default CurationPage;
