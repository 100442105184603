import Navigation from "../components/Nav";
import MenuBar from "../components/MenuBar";
import React, {useState} from "react";
import {Content, MainContainer, MainContent} from "../styles/styles";
import styled from "styled-components";
import {FileUploadContainer} from "../styles/FileUploadStyles";
import {useNavigate} from "react-router-dom";

const NewProjectPage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
`;

const Button = styled.button`
    font-size: 49px;
    font-weight: 700;
    width: 262px;
    height: 130px;
    color: #DEDFE2;
    padding: 10px;
    cursor: pointer;
    border-radius: 12px;
    background: #F7F8F9;
    border: 1px solid #DEDFE2;
`;

const MyPage = () => {
    const navigate = useNavigate();

    const handleNewProjectClick = () => {
        navigate('/project');
    };


    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                    <FileUploadContainer>
                        <div style={{
                            fontSize: '32px',
                            fontWeight: '700',
                            padding: '16px 0',
                            marginBottom: '20px',
                        }}>나의 프로젝트
                        </div>
                        <Button onClick={handleNewProjectClick}>+</Button>
                    </FileUploadContainer>
                </MainContent>
            </Content>
        </MainContainer>
    )
}

export default MyPage;
