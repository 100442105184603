import MenuBar from "../components/MenuBar";
import Navigation from "../components/Nav";
import React, {useState} from "react";
import {FileUploadContainer} from "../styles/FileUploadStyles";
import {Content, MainContainer, MainContent} from "../styles/styles";
import {useNavigate} from "react-router-dom";
import logo from "../assets/project/cubes.svg";
import {IoChatbubbleEllipsesOutline} from "react-icons/io5";
import { PiVideoLight } from "react-icons/pi";
import {Button, Product, ProductBtn} from "../styles/ProjectStyles";

const ProjectPage = () => {
    const navigate = useNavigate();

    const handleQurationClick = () => {
        navigate('/quration');
    };

    // subtitle generation으로 이동
    const onClickSubtitleGeneration = () => {
        navigate('/upload', {state: {isSubtitleGeneration: true}});
    }

    const onClickSourceSeparation = () => {
        navigate('/upload', {state: {isSourceSeparation: true}});
    }

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                    <FileUploadContainer>
                        <div style={{
                            fontSize: '32px',
                            fontWeight: '700',
                            padding: '16px 0',
                            marginBottom: '20px',
                        }}>새 프로젝트 시작하기
                        </div>
                        <Button onClick={handleQurationClick}>
                            <img style={{
                                width: '72px',
                                marginBottom: '15px',
                            }} src={logo} alt="cubes"/>
                            프로젝트 만들기
                            <span style={{
                                fontSize: '16px',
                                fontWeight: '300',
                                marginTop: '10px',
                            }}>큐레이션을 통해 제품을 고르고, 원하는 기능을 추가해보세요.</span></Button>
                        <Product>
                            이미 완성된 제품으로 손쉽게 시작할 수도 있습니다.<br/>
                            <span>Audion’s Suggestions</span>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                            }}>
                                <ProductBtn onClick={onClickSubtitleGeneration}>
                                    <IoChatbubbleEllipsesOutline size='30px'/>
                                    Subtitle Generation<br/>
                                    <span>New Project</span>
                                </ProductBtn>
                                <ProductBtn onClick={onClickSourceSeparation}>
                                <PiVideoLight size='30px'/>
                                    Source Separation<br/>
                                    <span>New Project</span>
                                </ProductBtn>
                            </div>
                        </Product>
                    </FileUploadContainer>
                </MainContent>
            </Content>
        </MainContainer>
    );
}

export default ProjectPage;
