import styled from "styled-components";

const MainContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

const Content = styled.div`
    display: flex;
    padding: 20px 72px;
`;

const MainContent = styled.div`
    display: inline-flex;
    flex-direction: row; /* 기본값이지만 명시적으로 설정 */
    width: 100%;
    @media (min-width: 1440px) {
        margin: 0 auto; /* 중앙 정렬 */
        max-width: 1440px; /* 컨텐츠 최대 너비 */

    }
`;

const LeftContent = styled.div`
    flex: 1;
    min-width: 400px;
    max-width: 700px;
    padding: 0 24px;
    //background-color: #f0f0f0;
`;

const RightContent = styled.div`
    flex: 1;
    min-width: 400px;
    max-width: 700px;
    //background-color: #282c34;
`;

const Title = styled.div`
    padding: 16px 0;
    margin-bottom: 16px;
    color: #0D141C;
    font-size: 32px;
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    text-align: left;
    display: inline-flex;
    align-items: center;
    line-height: 40px;
    //background: #F7FAFC;
`;

export const VideoStyle = styled.video`
    width: 100%;
    height: auto;
    border-radius: 12px;
`;

export const VideoInfoContainer = styled.div`
    margin: 16px 0;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
`;

export const VideoInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const VideoTitle = styled.div`
    color: #0D141C;
    font-size: 24px;
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    //width: 330px;
    //overflow: hidden; /* 텍스트가 넘칠 때 숨김 */
    //text-overflow: ellipsis; /* 넘치는 텍스트를 '...'으로 표시 */
    //white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    //width: 500px;
    //overflow: hidden; /* 텍스트가 넘칠 때 숨김 */
    //text-overflow: ellipsis; /* 넘치는 텍스트를 '...'으로 표시 */
    //white-space: nowrap; /* 텍스트를 한 줄로 표시 */
`;

export const SubDownloadButton = styled.a`
    
    background-color: #30B0C7;
    color: white;
    padding: 6px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 115px;
    font-weight: 600;
    font-size: 12px;
    font-family: Pretendard,sans-serif;
    text-align: center;
    line-height: 24px;
    
    &:hover {
        
    }
`;

export const VideoInfo = styled.div`
    color: black;
    font-size: 12px;
    font-family: Pretendard, sans-serif;
    font-weight: 300;
    word-wrap: break-word;
    line-height: 21px;
`;

export {
    MainContainer,
    Content,
    MainContent,
    LeftContent,
    RightContent,
    Title
};
