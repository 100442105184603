import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        activeButton: "mypage", // 기본값
    },
    reducers: {
        setActiveButton: (state, action) => {
            state.activeButton = action.payload;
        },
    },
});

export const { setActiveButton } = navigationSlice.actions;
export default navigationSlice.reducer;
