import styled from "styled-components";

// 스타일 정의
export const SignupContainer = styled.div`
    display: flex;
    height: 100vh;
    background: radial-gradient(circle, rgba(148, 177, 200, 0.8) 0%, rgba(48, 176, 199, 0.45) 20%, rgba(148, 177, 200, 0.52) 40%, rgba(255, 255, 255, 0) 100%);
`;

export const ImageSection = styled.div`
    flex: 1;
    //background-image: url('https://via.placeholder.com/600'); /* 이미지 URL */
    //background-size: cover;
    background-position: center;
`;

export const FormSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
`;

export const FormContainer = styled.div`
    width: 100%;
    max-width: 520px;
    height: 100%;
    padding: 36px;
    border-radius: 12px; /* 필요에 따라 값 변경 */
    display: flex;
    flex-direction: column;
    //border: 1px #878787 solid;
    background-color: #fff; /* 배경색 추가 */
    align-items: center;
    justify-content: center;
    
    img{
        width: 38px;
        margin-bottom: 18px;
    }

    .header{
        font-size: 32px;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Label = styled.label`
    margin-top: 10px;
    font-weight: bold;
    color: #333;
`;

export const Input = styled.input`
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

export const SubmitButton = styled.button`
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    
    font-size: 16px;
    

    &:hover {
        background-color: #0056b3;
    }
`;
