import React, {useState, useEffect} from 'react';
import MenuBar from '../components/MenuBar';
import {
    Content,
    LeftContent,
    MainContainer,
    MainContent,
    RightContent,
    SubDownloadButton,
    Title,
    VideoInfoContainer,
    VideoInfoRow,
    VideoTitle
} from '../styles/styles';
import Navigation from "../components/Nav";

import {useLocation} from "react-router-dom";
import Tab from "../components/Tabs";

const SourceSeparationPage = () => {
    const location = useLocation();
    let { result, selectedInstruments } = location.state || {};
    const videoUrl = result.youtube.videoPath ? `https://audion.mago52.com/files/${result.youtube.videoPath}` : `https://audion.mago52.com/files/${result.filePath}`;

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const handleDownload = async (instrument) => {
        const downloadUrl = `https://audion.mago52.com/files/${result.content.result.separated_audios[instrument]}`;

        try {
            const response = await fetch(downloadUrl);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${instrument}.wav`;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(`Error downloading ${instrument} file:`, error);
        }
    };

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                    <LeftContent>
                        <Title>음원 분리기</Title>
                        <video controls style={{width: '80%', height: 'auto', borderRadius: '12px', marginBottom: '20px'}}>
                            <source src={videoUrl} type="video/mp4"/>
                            <source src={videoUrl} type="video/avi"/>
                            <source src={videoUrl} type="video/mov"/>
                            <source src={videoUrl} type="video/ogg"/>
                            <source src={videoUrl} type="video/webm"/>
                            <source src={videoUrl} type="audio/mp3"/>
                            <source src={videoUrl} type="audio/aac"/>
                            <source src={videoUrl} type="audio/flac"/>
                            <source src={videoUrl} type="audio/wav"/>
                            <source src={videoUrl} type="audio/webm"/>
                        </video>
                        <audio controls style={{width: '80%', height: '50px', borderRadius: '12px'}}>
                            <source src={result && `https://audion.mago52.com/files/${result.filePath}`}
                                    type="audio/flac"/>
                        </audio>
                        <VideoInfoContainer>
                            <VideoInfoRow>
                                {/* <VideoTitle>{getFileNameWithoutExtension(result.content.filePath)}</VideoTitle> */}
                            </VideoInfoRow>
                        </VideoInfoContainer>
                        {/*<Tab*/}
                        {/*    result=''*/}
                        {/*    text=''*/}
                        {/*    summary=''*/}
                        {/*    meta={result.content.result.audio_info}*/}
                        {/*/>*/}
                    </LeftContent>
                    <RightContent>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center'
                        }}>
                            <Title>음원 다운로드</Title>
                            <p>음원을 분리한 결과입니다. 다운로드 버튼을 눌러 다운로드 받으세요.</p>
                            {selectedInstruments && selectedInstruments.map((instrument) => (
                                result.content.result.separated_audios[instrument] && (
                                    <div key={instrument} style={{
                                        marginTop: '30px',
                                        marginBottom: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                        <p style={{
                                            fontWeight: 'bold',
                                            marginRight: '20px',
                                            fontSize: '20px'
                                        }}>{instrument}</p>
                                        <audio controls style={{borderRadius: '12px', marginRight: '20px'}}>
                                        <source src={`https://audion.mago52.com/files/${result.content.result.separated_audios[instrument]}`} type="audio/flac" />
                                            <source src={`https://audion.mago52.com/files/${result.content.result.separated_audios[instrument]}`} type="audio/wav" />
                                            <source src={`https://audion.mago52.com/files/${result.content.result.separated_audios[instrument]}`} type="audio/mp3" />
                                        </audio>
                                        <SubDownloadButton onClick={() => handleDownload(instrument)}>다운로드</SubDownloadButton>
                                    </div>
                                )
                            ))}
                        </div>
                    </RightContent>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default SourceSeparationPage;
